import { Form } from '~/common/components/Form/Form';
import { useForm } from 'react-hook-form';
import { Checkbox } from '~/common/components/Checkbox/Checkbox';
import { Block } from '~/common/components/Block/Block';
import { Button } from '~/common/components/Button/Button';
import { Input } from '~/common/components/Input/Input';
import { AsyncSelect } from '~/common/components/Select/AsyncSelect';
import { setErrorsFunction } from '~/common/libs/FormHelpers/FormHelpers';
import { Select } from '~/common/components/Select/Select';
import { useEffect, useState } from 'react';
import { Link } from '~/common/components/Link/Link';
import { Textarea } from '~/common/components/Textarea/Textarea';
import {
  googleColabOptions,
  // programProficiencyOptions,
  // experienceOptions,
  internetQualityOptions,
  getDatasetsOptions,
  // yesOrNo,
  lowfiStudentOptions,
  howDidYouHearAboutUsOptions,
  howDidYouHearAboutUsOptionsLabels,
  contactForFutureOptions,
  priorNMAExperienceOptions,
  priorNMAExperienceOptionsLabels,
  primaryExpertiseOptions,
} from '~/modules/applications/models/application.model';
import {
  useCreateStudentApplicationRequest,
  useGetCourseDataSets,
  useLoadApplicationsRequest,
  useUpdateStudentApplicationRequest,
} from '~/modules/applications/models/application.hooks';
import { useLoadCountriesRequest, useOnError, useSnackbar } from '~/modules/app/models/app.hooks';
import dayjs from 'dayjs';
import { history } from '~/app/history/history';
import { applicationsRoutes } from '~/modules/applications/router/Router';
import { userRoutes } from '~/modules/user/router/Router';
import { useDispatch } from 'react-redux';
import {
  createLogistic,
  updateLogistic,
} from '~/modules/user/redux/user.actions';
import { useUser } from '~/modules/user/models/user.hooks';
import { getStudentSeniority } from './utils';
import { scrollToTop, scrollToFirstError } from '~/modules/app/models/app.model';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

const formNames = {
  how_did_you_hear_about_us: 'how_did_you_hear_about_us',
  maillist_or_other: 'maillist_or_other',
  contact_for_future: 'contact_for_future',
  prior_NMA_experience: 'prior_NMA_experience',
  residing_country_during_course: 'residing_country_during_course',
  has_access_google_colab: 'has_access_google_colab',
  internet_works_fine: 'internet_works_fine',
  answers: 'answers',
  data_set: 'data_set',
  second_data_set: 'second_data_set',
  lowfi_track: 'lowfi_track',
  preprints_or_peer_reviewed_publications: 'preprints_or_peer_reviewed_publications',
  primary_expertise: 'primary_expertise',
  about_educational_background: 'about_educational_background',
  agree_aforementioned_forty_hours: 'agree_aforementioned_forty_hours',
  agree_to_abide_code_of_conduct: 'agree_to_abide_code_of_conduct',
  agree_terms_and_conditions: 'agree_terms_and_conditions',
};

export const CreateStudentApplicationForm = ({
  defaultValues,
  isUpdate = false,
  previousPage,
  course,
  applicationId,
}) => {
  const { register, handleSubmit, setError, trigger, setValue, errors, watch } = useForm({
    defaultValues,
  });
  const setErrors = setErrorsFunction(setError);
  const datasets = useGetCourseDataSets(course.id);
  const datasetsOptions = getDatasetsOptions(datasets);
  const createStudentApplication = useCreateStudentApplicationRequest();
  const updateStudentApplication = useUpdateStudentApplicationRequest();
  const { setSnackbar } = useSnackbar();
  const loadApplications = useLoadApplicationsRequest();
  const dispatch = useDispatch();
  // get user for seniority eval
  const user = useUser();
  // question related constants
  const questions = course.questions;
  const programmingQuestions = questions.filter(question => question.category === 'course_spec_prog');
  const domainQuestions = questions.filter(question => question.category === 'course_spec_dom');

  // answers array to accumulate answers and send them to api
  // const answers = questions.map(question => ({ question: question.id, answer_choice: '1' }));
  const dropdownQuestions = questions.filter(question => question.category !== 'technical_dom')
    .map(question => ({ id: question.id }));
  const dropdownAnswers = dropdownQuestions.map(question => ({ question: question.id, answer_choice: '1' }));

  const [dropdownErrors, setDropdownErrors] = useState({});

  // Validation and Error handling for dropdrown questions
  const validateDropdownAnswers = data => {
    const unasweredQuestions = dropdownQuestions.filter(
      question => (typeof data[`answers_questionID_${question.id}`] === 'undefined')
    )
    if (unasweredQuestions.length) {
      unasweredQuestions.forEach(question => {
        setDropdownErrors(errors => ({
          ...errors,
          [`answers_questionID_${question.id}`]: 'This field is required.'
        }))
      })
      return false;
    } else {
      return true;
    }
  };

  const checkDropdownErrors = () => {
    const updatedErrors = {};
    Object.entries(dropdownErrors).forEach(([key, error]) => {
       if (typeof watch()[key] === 'undefined') {
        updatedErrors[key] = error;
      }
    });
    setDropdownErrors(updatedErrors);
  };


  useEffect(() => {
    scrollToFirstError(dropdownErrors);
  }, [dropdownErrors]);

  useEffect(() => {
    register({ name: formNames.how_did_you_hear_about_us });
    register({ name: formNames.contact_for_future });
    register({ name: formNames.prior_NMA_experience });
    register({ name: formNames.primary_expertise });
    register({ name: formNames.residing_country_during_course });
    register({ name: formNames.internet_works_fine });
    register({ name: formNames.has_access_google_colab });
    register({ name: formNames.answers});
    register({ name: formNames.data_set });
    register({ name: formNames.second_data_set });
    register({ name: formNames.lowfi_track });
  }, [register]);

  const setValueWithTrigger = (name, value) => {
    setValue(name, value);
    trigger(name);
  };

  const parseAnswers = data => {
    dropdownAnswers.forEach(answer => {
      answer.answer_choice = data[`answers_questionID_${answer.question}`];
    })
    data.answers = dropdownAnswers;
  };

  // handles default values for dropdown questions on update
  const defaultAnswers = question => {
    const prevAnswers = defaultValues?.answers || [];
    const defaultAnswer = prevAnswers.find(answer => answer.question === question.id);
    if (!defaultAnswer || typeof defaultAnswer.answer_choice === 'string') {
      return null;
    } else {
      const defaultChoice = question.answer_choices.find(choice => choice.id === defaultAnswer.answer_choice);
      if (defaultChoice) {
        defaultValues[`answers_questionID_${question.id}`] = defaultChoice.id;
        return defaultChoice.answer_text;
      } else {
        return null
      }
    }
  };

  const sortAnswers = (a, b) => {
    if (a.value > b.value) {
      return 1;
    } else if (a.value < b.value) {
      return -1;
    } else {
      return 0;
    }
  };

  const renderSkillQuestions = (questions) => {
    return (
      questions.map((question) => {
            const inputFormName = formNames.answers + `_questionID_${question.id}`;
            const options = question.answer_choices.map(choice => ({
              value: choice.rating,
              label: choice.answer_text,
              id: choice.id,
            })).sort(sortAnswers);
            return (
              <Form.Row>
                <Select
                  name={inputFormName}
                  onChange={option => {
                    setValueWithTrigger(inputFormName, option.id);
                    checkDropdownErrors();
                  }}
                  options={options}
                  label={question.question_text}
                  error={dropdownErrors[inputFormName]}
                  defaultValue={defaultAnswers(question)}
                  innerRef={register(
                    { name: inputFormName },
                  )}
                />
              </Form.Row>
            );
      })
    );
  };

  const onError = useOnError(setErrors);
  // TODO: change num prefs
  const getAvailablePreference = () =>{ 
    for (let i=1; i<=6; i++) {
      let application_key = `preferred_application_${i}`
      if (user.logistic[application_key] === null) {
        return application_key;
      }
    }
      return 'preferred_application_6'
  }
   // TODO: Allow null and validate on frontend
   const validateOtherFields = data => {
    const placeholderText = '';
    if (!isMaillistOrOther) {
      data.maillist_or_other = placeholderText;
    }
  }


  const onSubmit = data => {
    if (!validateDropdownAnswers(data)) {
      setSnackbar('Required fields missing')
      return;
    }
    validateOtherFields(data);
    // set seniority in payload
    let seniority = getStudentSeniority(user);
    if (seniority === undefined) {
      seniority = 'undefined';
    }
    data['seniority'] = seniority;
    // If answers are validated, accumulate all answers into an object for db consumption
    parseAnswers(data);
    if (isUpdate) {
      updateStudentApplication(applicationId, data)
        .then(() => {
          setSnackbar('Application is updated');
          loadApplications();
          // history.push(applicationsRoutes.homepage);
          history.push(userRoutes.applications);
        })
        .catch(onError);
    } else {
      createStudentApplication(course.id, data)
        .then(() => {
          setSnackbar('Application is submitted');
          // history.push(applicationsRoutes.homepage);
          let preference_key = getAvailablePreference();
          const logisticValues = { ...user.logistic,  [preference_key] : course.name + " student" }; 
          dispatch(updateLogistic(logisticValues, logisticValues.id))
            .then(() => {
              history.push(userRoutes.applications);
            })
            .catch(onError);
          history.push(userRoutes.applications);
        })
        .catch(onError);
    }
  };

  const loadCountriesOptions = useLoadCountriesRequest();
  // const country = watch(formNames.residing_country_during_course);

  const howDidYouHearAboutUs = watch("how_did_you_hear_about_us"); 
  const isMaillistOrOther = howDidYouHearAboutUs?.includes(howDidYouHearAboutUsOptions.maillist) || 
                            howDidYouHearAboutUs?.includes(howDidYouHearAboutUsOptions.other);

  const startDate = dayjs(course.deadlines?.start_date);
  const endDate = dayjs(course.deadlines?.end_date);

  const formattedStartDate = startDate.utc().format('MMMM DD, YYYY');
  const formattedEndDate = endDate.utc().format('MMMM DD, YYYY');

  // why is this not used?
  // const studentApplicationEndDate = dayjs(course.deadlines?.student_application_end_date)
  //   .utc()
  //   .format('MMMM DD, YYYY HH:mm');
  //
  return (
    <Block title={'Student Application - ' + course.name} className="create-student-application-form narrow">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Row>
          <Form.Label>How did you hear about Climatematch or Neuromatch? Select all that apply.</Form.Label>
          {Object.entries(howDidYouHearAboutUsOptions).map(([key, value]) => (
            <Checkbox
              innerRef={register}
              name={formNames.how_did_you_hear_about_us}
              value={value}
              defaultValue={formNames.how_did_you_hear_about_us}
              text={howDidYouHearAboutUsOptionsLabels[value]}
              key={key}
            />
          ))}
          <div className="error">{errors?.how_did_you_hear_about_us?.message}</div>
        </Form.Row>
        {isMaillistOrOther && (
          <Form.Row>
            <Input
              label="Please specify how you heard about Climatematch or Neuromatch: "
              name={formNames.maillist_or_other}
              placeholder="Specify here..." 
              innerRef={register}
              error={errors?.maillist_or_other?.message}
            />
          </Form.Row>
        )}
        <Form.Row>
          <Select
            name={formNames.contact_for_future}
            onChange={option => {
              setValueWithTrigger(formNames.contact_for_future, option?.value ?? option);
            }}
            options={contactForFutureOptions}
            label={
              <>
                Would you be interested in being contacted in the future to help
                spread the word about new courses among your community?
              </>
            }
            error={errors.contact_for_future?.message}
            defaultValue={defaultValues?.contact_for_future}
          />
        </Form.Row>
        <Form.Row>
          <Form.Label>Did you participate in any previous Academies? (Please check all that apply)</Form.Label>
          {Object.entries(priorNMAExperienceOptions).map(([key, value]) => (
            <Checkbox
              innerRef={register}
              name={formNames.prior_NMA_experience}
              value={value}
              defaultValue={formNames.prior_NMA_experience}
              text={priorNMAExperienceOptionsLabels[value]}
              key={key}
            />
          ))}
          <div className="error">{errors?.prior_NMA_experience?.message}</div>
        </Form.Row>

        <Form.Row>
          <AsyncSelect
            name={formNames.residing_country_during_course}
            label={
              <>What country/region will you be living in during the course? ({endDate.utc().format('MMMM YYYY')})?</>
            }
            onChange={option => {
              setValueWithTrigger(formNames.residing_country_during_course, option?.value ?? option);
            }}
            loadOptions={loadCountriesOptions}
            //isCreatable
            error={errors[formNames.residing_country_during_course]?.message}
            defaultValue={defaultValues?.residing_country_during_course}
          />
        </Form.Row>
        <Form.Row>
          <Select
            name={formNames.has_access_google_colab}
            onChange={option => {
              setValueWithTrigger(formNames.has_access_google_colab, option?.value ?? option);
            }}
            options={googleColabOptions}
            label={
              <>
                Will you have access to Google Colab (
                <Link to="https://colab.research.google.com/" isCrossDomain newTab>
                  colab.research.google.com
                </Link>
                ) during the course? We will try to find alternative accommodations if not.
              </>
            }
            error={errors[formNames.has_access_google_colab]?.message}
            defaultValue={defaultValues?.has_access_google_colab}
          />
        </Form.Row>
        <Form.Row>
          <Select
            name={formNames.internet_works_fine}
            onChange={option => {
              setValueWithTrigger(formNames.internet_works_fine, option?.value ?? option);
            }}
            options={internetQualityOptions}
            label="Will you have access to a stable internet connection during the course?"
            error={errors[formNames.internet_works_fine]?.message}
            defaultValue={defaultValues?.internet_works_fine}
          />
        </Form.Row>
        <Form.Row>
          <Select
            label="Please select your primary domain of expertise. This question is for demographics purposes and will not affect your application for the course. "
            name={formNames.primary_expertise}
            options={primaryExpertiseOptions}
            onChange={option => {
              setValueWithTrigger(formNames.primary_expertise, option?.value ?? option);
            }}
            defaultValue={defaultValues?.primary_expertise}
            error={errors[formNames.primary_expertise]?.type}
          />
        </Form.Row>
        {renderSkillQuestions(programmingQuestions)}
        {renderSkillQuestions(domainQuestions)}
        <h5>Low-Fi Track Option</h5>
        <Form.Row>
          <Select
            name={formNames.lowfi_track}
            onChange={option => {
              setValueWithTrigger(formNames.lowfi_track, option?.value ?? option);
            }}
            options={lowfiStudentOptions}
            label='
              We have designed the low-fi track to be more accessible for those who may have difficulties being on Zoom all day. This may be a result of internet instability, electricity instability, having a learning disability, or other access needs where multiple hours on Zoom is difficult. Students will work on their own for most of the tutorial time and come together once a day for about 30-45 minutes on Zoom to discuss questions with your TA. 
              The "low-fi" status only affects tutorial time, projects time stays the same. 
              Would you like to be placed in a low-fi pod if there is one available (availability is limited)? Please only select this option if you feel it would improve your learning experience. 
            '
            error={errors[formNames.lowfi_track]?.message}
            defaultValue={defaultValues?.lowfi_track}
          />
        </Form.Row>
        <Form.Row>
          <Select
            name={formNames.data_set}
            onChange={option => {
              setValueWithTrigger(formNames.data_set, option?.value ?? option);
            }}
            options={datasetsOptions}
            label="Which of these types of projects would you prefer? We will assign you to a project group based on your preference below and this will be impossible to change later."
            error={errors[formNames.data_set]?.message}
            defaultValue={defaultValues?.data_set}
          />
        </Form.Row>
        <Form.Row>
          <Select
            name={formNames.second_data_set}
            onChange={option => {
              setValueWithTrigger(formNames.second_data_set, option?.value ?? option);
            }}
            options={datasetsOptions}
            label="What is your second preference for your group project?"
            error={errors[formNames.second_data_set]?.message}
            defaultValue={defaultValues?.second_data_set}
          />
        </Form.Row>
        <Form.Row>
          <Textarea
            name={formNames.preprints_or_peer_reviewed_publications}
            innerRef={register}
            label="Do you have any preprints or peer-viewed publications in which you were a substantial author? If so, please paste titles and links here:"
            error={errors[formNames.preprints_or_peer_reviewed_publications]?.message}
          />
        </Form.Row>
        <Form.Row>
          <Textarea
            name={formNames.about_educational_background}
            innerRef={register}
            label="Why do you want to be part of the course as a student? Briefly tell us about your educational background, research interests, motivation, and expected benefit of attending (50-300 words)."
            error={errors[formNames.about_educational_background]?.message}
          />
        </Form.Row>
        <Form.Row>
          <Checkbox
            name={formNames.agree_aforementioned_forty_hours}
            innerRef={register({ required: true })}
            text={
              <>
                (required) I understand that this course requires a time commitment of at least 8 hours each weekday from  {formattedStartDate} -{' '}
                {formattedEndDate}. Due to the intensity and historical performance statistics of the course, it is very difficult to complete this course while maintaining a full-time job.
              </>
            }
            error={errors[formNames.agree_aforementioned_forty_hours]?.type}
          />
        </Form.Row>
        <Form.Row>
          <Checkbox
            name={formNames.agree_to_abide_code_of_conduct}
            innerRef={register({ required: true })}
            text={
              <>
                (required) I agree to abide by the{' '}
                <Link
                  to="https://github.com/NeuromatchAcademy/precourse/blob/master/CODE_OF_CONDUCT.md"
                  isCrossDomain
                  newTab
                >
                  Code of Conduct
                </Link>
              </>
            }
            error={errors[formNames.agree_to_abide_code_of_conduct]?.type}
          />
        </Form.Row>
        <Form.Row>
          <Checkbox
            name={formNames.agree_terms_and_conditions}
            innerRef={register({ required: true })}
            text={
              <>
                (required) I have read and agree to the{' '}
                <Link
                  to='https://docs.neuromatch.io/p/kpUb79-ES8l_Gj/Privacy-Policy'
                  isCrossDomain
                  newTab
                >
                  terms and conditions
                </Link>
                {' '}in the Neuromatch Privacy Policy, summarized as:
                <ul>
                  <li>Your data will be shared with select Neuromatch staff and core volunteers to manage the program </li>
                  <li>Your anonymized data may be shared with funding agencies</li>
                  <li>We may contact you by email to update you on your application</li>
                </ul>
              </>
            }
            error={errors[formNames.agree_terms_and_conditions]?.type}
          />
        </Form.Row>
        <Form.Row>This submission is final so please review your answers to ensure they are correct!</Form.Row>
        <Form.Row className="create-student-application-form__buttons">
          {isUpdate ? (
            <>
              <Button type="submit">Update application</Button>
            </>
          ) : (
            <>
              <Button
                onClick={() => {
                  previousPage();
                  scrollToTop();
                }}
              >
                Previous Page
              </Button>
              <Button type="submit">Submit application</Button>
            </>
          )}
        </Form.Row>
      </Form>
    </Block>
  );
};
