import { CoursesBlocks } from '~/modules/applications/pages/Home/blocks/components/CoursesBlocks/CoursesBlocks';
import { ApplicationsBlock } from '~/modules/applications/pages/Home/blocks/components/ApplicationsBlock/ApplicationsBlock';
import { Markdown } from '~/common/components/Markdown/Markdown';
import { Collapse } from '~/common/components/Collapse/Collapse';
import { Link } from '~/common/components/Link/Link';
import { Button } from '~/common/components/Button/Button';
import { useHistory } from 'react-router-dom';
import Logo from '~/common/assets/img/header/logo-black.png';

export const Home = () => {
  const history = useHistory();
  const handleClick = () => {history.push('/applications')}
  return (
    <div className="home narrow mt-20">
      {/*<ApplicationsBlock />*/}
      <img className='dual-logo' src={ Logo } alt='logo'/>
      <Collapse title="General information" defaultExpanded={false}>
        <Markdown>
          {
            "##### **What is this program?**" +
            "  \n" +
            "  \n" +
            "We seek to democratize science by making science education inclusive and globally accessible. Our goal is to empower people from different backgrounds by providing access to education, networking, mentorship, and research opportunities." +
            "  \n" +
            "  \n" +
            "In our programs, students are matched into small groups and work together online, with a teaching assistant, through interactive problems, group projects, and professional development experiences." +
            "  \n" +
            "  \n" +
            "The courses run in parallel, so you can only be a part of **one** course per year. You can, however, submit up to three total applications. You will be asked to provide a preferred order of acceptance for all applications you submit. **You will not be able to enroll in a lower preference if accepted to a higher preference as they take place at the same time**." +
            "  \n" +
            "  \n" +
            "You can read more about our individual courses and their goals in the sections below.          " +
            "  \n" +
            "  \n" +
            "  \n" +
            "##### **Who is Neuromatch, Inc.?**" +
            "  \n" +
            "  \n" +
            "Neuromatch’s mission is to accelerate scientific innovation by facilitating inclusive, collaborative, and global participation in the computational sciences. We are a global not-for-profit with a community of hundreds of science enthusiasts from all over the world. Through our Academy programs, we build high-quality computational sciences curriculums and facilitate our annual synchronous Academy that supports the next generation of computational researchers." +
            "  \n" +
            "  \n" +
            "We believe in collaboration. The more awesome people that are part of the scientific community, the higher quality and more innovative research we’ll be able to produce." +
            "  \n" +
            "  \n" +
            "We believe in open science. All of our course materials are open-access and reusable under our CC-BY license, and we strive to embody open science principles in all we do." +
            "  \n" +
            "  \n" +
            "  \n" +
            "##### **Why should you apply to our courses?**" +
            "  \n" +
            "  \n" +
            "Our courses all provide a dynamic learning experience in which you collaborate with a global community of experts and peers, hone your problem-solving skills, complete mentored research projects, and build a supportive network that celebrates inclusivity and accessibility in science education. In the end, you’ll receive a course certificate, will have completed a piece of your own poster-worthy research project, and will leave with a new, strong network of peers.           " +
            " \n" +
            " \n" +
            "Apply to unlock your potential and propel your career forward." +
            "  \n" +
            "  \n"
          }
        </Markdown>
      </Collapse>
            {/*<Collapse title="What it means to be a student?">
        <Markdown>
          {
            "**This is an application for being a student at the Computational Tools for Climate Science  course in 2023.  If you have difficulty understanding some of it, consider using Google translate or another service.**" +
            "  \n" +
            "  \n" +
            "Course content is in English, limited amount of translations are/will be available for video content for certain languages. If you are in a language-specific pod, your TA will provide you the aid to overcome the language barrier. Due to technical difficulties it is not possible to provide the entire course translated, so some English proficiency is needed & encouraged." +

            "(highlighted as purple) Please note that enrolled students are expected to dedicate full-time effort, at least 8 hours a day and 5 days a week, to this 2-week intensive course. According to the experience of past students in Neuromatch Academy, it is very difficult  to complete this course while maintaining a full-time job." + "**What do we expect from students during the program?**" + "This is a tutorial-based teaching and community-building endeavor, with everything taught in Google Colab or Kaggle and Python. You don’t have to install any software, and everything will be taught through a combination of brief lectures, hands-on coding exercises, and professional development support." + "You will be placed in a pod of ~15 students and a teaching assistant . Each pod will be split into subgroups for research projects. Throughout the whole 2-week course, you will work on a research project related to one of eight research themes (descriptions:https://neuromatch.io/climate-science ) and be advised by an additional specialist project teaching assistant ." +
            "**Expertise required?**" +

            "All students need to have basic coding proficiency in Python, and should, for example, be able to write a small Python script/notebook. Those familiar with another coding language are highly encouraged to complete our asynchronous Python course based on content developed by Project Pythia [https://foundations.projectpythia.org/landing-page.html] before the beginning of course." +

            "Even though our course teaches cutting-edge computational tools in climate science, each topic is self-contained and no prior knowledge or experience in climate science is required. Our course requires only a fundamental science background in topics of math, statistics, physics and chemistry. If you do not have these skills or knowledge before the course, there are *lots* of options for catching up before you begin!  We will provide a detailed list of topics and recommended resources to help you get the background you need to succeed and get the most out of the course [a link that describes these in detail]" +

            "**How will selection occur?**" +
            "We strive to obtain representation in our course from every region on the globe. We will be making our decision based mainly on your likelihood  to complete the course, and how we can maximize the gender, geographic and economic diversity of the applicants. We want to emphasize that your level of proficiency in math, physics and chemistry is NOT a criteria for selection as long as you have the foundational knowledge mentioned above."
          }
        </Markdown>
      </Collapse>*/}

      <Collapse title="Course Information" defaultExpanded={false}>
        <Markdown>
            {
            "##### **Current course offerings**"
        }
        </Markdown>
        <CoursesBlocks withButtons={false}/>
        <Markdown>
            {
            " \n" +
            "  \n" +
            "  \n" +
            "##### **How is each course structured?**" +
            "  \n" +
            "  \n" +
            " * All courses are full-time, 8-hour/day, Monday-Friday commitments for both students and TAs." +
            "  \n" +
            " * All courses are virtual and take place via Zoom." +
            "  \n" +
            " * Students work in small learning groups, called pods, with about ~15 students and a regular teaching assistant. These groups are formed using the [neuromatch algorithm](https://elifesciences.org/labs/5ed408f4/neuromatch-algorithms-to-match-scientists), which matches students based on common interests, time zone, and preferred language." +
            "  \n" +
            "   * Each pod is split into two project groups during your first project session." +
            "  \n" +
            " * We offer 5 timeslots to accommodate our global audience. There’s one that works for everyone!" +
            "  \n" +
            " * The course is divided into curriculum time and project time." +
            "  \n" +
            "   * Curriculum time is the first part of the day and is 4.5 hours. During this time you’ll go through a series of Python tutorials with your regular TA, have group discussions, and also have an hour break." +
            "  \n" +
            "   * Project time takes place after the mid-day break and is 3 hours. During this time you work in your project groups on your research project. Your regular TA will briefly check in with your group, and your dedicated Project TA will spend about an hour with your group each day. This time is mostly self-directed by the group." +
            "  \n" +
            "  \n" +
            "  \n" +
            "##### **Diversity and Inclusivity**" +
            "  \n" +
            "  \n" +
            "Diversity is integral to our mission of providing excellent educational, training, and professional development opportunities to students worldwide. By nurturing a diverse community, we aim to broaden and deepen the educational experiences we provide to students, and to enhance the scholarly environment for teaching assistants, mentors, instructors, and organizers. We  thus strive to advance inclusive excellence in our student, teaching assistant, instructor, mentor, and organizer populations."+
            "  \n" +
            "  \n" +
            "We actively encourage everyone to apply: applicants will receive consideration for inclusion in our programs without regard to race, color, religion, sex, sexual orientation, gender identity, disability, age*, protected veteran status, or other protected categories covered by relevant nondiscrimination policies or laws. We will collect voluntary data on the race or gender of its students, employees, contractors and applicants to gauge the effectiveness of its efforts. We will report only summary statistics and not individual data points wherever we use this data." +
            "  \n" +
            "  \n" +
            "*Due to legal and liability restrictions on enrolling minors, we unfortunately cannot accommodate students or teaching assistants who are under the age of 18." +
            "  \n"
        }
        </Markdown>
      </Collapse>
      <Collapse title="Information for prospective students" defaultExpanded={false}>
        <Markdown>
            {
            "##### **What do we expect from students during the course?**" +
            "  \n" +
            "  \n" +
            "Enrolled students are expected to dedicate full-time effort, at least 8 hours a day and 5 days a week, to these intensive courses for their duration (2-3 weeks depending on the course). According to the experience of past students in Neuromatch Academy, it is very difficult  to complete this course while maintaining a full-time job.  " +
            "  \n" +
            "  \n" +
            "This is a tutorial-based teaching and community-building endeavor, with everything taught in Python using Google Colab, Kaggle, or JupyterHub. You don’t have to install any software, and everything will be taught through a combination of brief lectures, hands-on coding exercises, and professional development support.   " +
            "  \n" +
            "  \n" +
            "You will be placed in a **pod** of ~15 students and a regular teaching assistant . Each pod will be split into two groups for research projects. Throughout the whole course, you will work on a research project and be advised by a project specific teaching assistant. More information about the types of projects in each course can be found under the course-specific description. " +
            "  \n" +
            "  \n" +
            "Students who complete the curriculum portion of the course will receive a personalized certificate of completion. For students who also complete the project portion, they will receive a project badge on their certificate. Please note that missing more than two days of the course may result in no certificate being granted. If you know of existing conflicts and will not be able to attend the entire course with no more than 1-2 days of absence, you should not enroll this year." +
            "  \n" +
            "  \n" +
            "  \n" +
            "##### **What does it cost?**"+
            "  \n" +
            "  \n" +
            "At Neuromatch, our mission is to democratize education and research in the computational sciences globally. Our course fees are essential to sustaining our programs, and your fees directly support the Teaching Assistants (TAs) who dedicate an entire month to teaching you. Without these course fees, Neuromatch would not be able to operate." +
            "  \n" +
            "  \n" +
            "To promote accessibility, we use a regional fee adjustment model based on GDP per capita data from the World Bank. This approach ensures that participants from diverse socioeconomic and geographical backgrounds can access our courses, making high-quality education truly global. You can estimate your course fees using our [Course Fee Calculator](https://neuromatchacademy.github.io/widgets/cola.html)." +
            "  \n" +
            "  \n" +
            "Please note that a non-refundable processing fee (also subject to regional fee adjustments) is required to cover administrative costs. Please see our [Course Refund Policy](https://docs.neuromatch.io/p/GJuBoDwfThwsFN/Course-Refund-Policy)." +
            "  \n" +
            "  \n" +
            "  \n" +
            "##### **What expertise is required?**" +
            "  \n" +
            "  \n" +
            "Generally, our programs are made for people that have some foundational knowledge in the following topics: " +
            "  \n" +
            " * Python (we offer a python pre-course to help with this) " +
            "  \n" +
            " * Mathematics" +
            "  \n" +
            " * Statistics" +
            "  \n" +
            "  \n" +
            "Specific information on course requirements can be found in the course descriptions above" +
            "  \n" +
            "  \n" +
            "  \n" +
            "##### **What is the timeline?**" +
            "  \n" +
            "  \n" +
            " * Applications Open - end of February" +
            "  \n" +
            " * Applications Close - end of March" +
            "  \n" +
            " * Application Decisions - mid to end of April" +
            "  \n" +
            " * Enrollment Deadline (including payment for students) - early May" +
            "  \n" +
            " * Matching Date (this is when you’ll know which timeslot you are in and information about your pod) - beginning of June" +
            "  \n" +
            " * Synchronous TA Training (required for all TAs) - about a week prior to the course for 2-3 hours. Lead TAs are required to go to one additional day of training." +
            "  \n" +
            " * Orientation (required for both students and TAs) - about a week prior to the course for 2 hours" +
            "  \n" +
            " * Course Starts - July!" +
            "  \n" +
            "  \n" +
            "##### **Continuing your research project with the Impact Scholars program**" +
            "  \n" +
            "  \n" +
            "Students who complete the project portion of the course can apply to continue their research project through our Impact Scholars program. You can learn more about the program [here](https://impact-scholars.neuromatch.io/impact-scholars/intro.html)." +
            "\n"
        }
        </Markdown>
      </Collapse>
      <Collapse title="Information for prospective teaching assistants" defaultExpanded={false}>
          <Markdown>
            {
              "##### **What are the responsibilities of teaching assistants?**" +
              "  \n" +
              "  \n" +
              "All teaching assistant roles are full-time, 8 hours/day, Monday-Friday for the duration of the course." +
              "  \n" +
              "  \n" +
              "During the course, students will work on tutorials for 3.5hrs each day and on group research projects for another 3hrs. To guide them through both activities, we will have two types of teaching assistants: regular and project teaching assistants. Regular teaching assistants form a “pod” with a single group of students and guide them through daily tutorials and assist in their research projects. In contrast, project teaching assistants  are assigned to several groups, but only assist with research projects and not with tutorials. The hourly commitment and stipend are the same for both roles. You can indicate your preference for being either a regular teaching assistant or a project teaching assistant. We can't guarantee your preferred choice, but we will try to accommodate. " +
              "  \n" +
              "  \n" +
              "  \n" +
              "##### **Requirements for All Teaching Assistants**" +
              "  \n" +
              "  \n" +
              "All teaching assistants need to have:" +
              "  \n" +
              " * A fundamental background in the course topic you are applying to" +
              "  \n" +
              " * Proficiency in Python" +
              "  \n" +
              "  \n" +
              "Given the breadth of topics being taught, we absolutely do not expect teaching assistants to be familiar with all sub-disciplines of the course topic. Teaching assistants will participate in training sessions where they will receive curriculum materials, tutorials and the solution materials of the tutorials. We are looking for teaching assistants’ willingness to acquire the required knowledge so that they can then deliver it, demonstrating a solid understanding of the material chosen to present. Other relevant requisites are:" +
              "  \n" +
              "  \n" +
              " * Completed Undergraduate Degree" +
              "  \n" +
              "    * We define undergraduate degree as a ~4-year program where you have finished all course work requirements needed to graduate. For some regions you may not receive a degree until finishing additional requirements (2-year internships, etc.), you can still apply if you meet the above requirements of our definition." +
              "  \n" +
              " * Accepting terms of agreement" +
              "  \n" +
              " * Accepting time commitment" +
              "  \n" +
              " * Strong verbal and written communication skills in English" +
              "  \n" +
              "  \n" +
              "Teaching Assistants are expected to guide the students through the program by facilitating their curriculum-based learning experience. As such, teaching assistants are problem-based learning facilitators, more than teachers (read more about problem-based learning (PBL) philosophy in [Yew & Goh, 2016](https://www.sciencedirect.com/science/article/pii/S2452301116300062))." +
              "  \n" +
              "  \n" +
              "Main responsibilities are:" +
              "  \n" +
              "  \n" +
              " * Provide support, empathy, and inspiration" +
              "  \n" +
              " * Facilitate thinking, growth, and engagement" +
              "  \n" +
              " * Guide students to answers rather than directly giving them answers" +
              "  \n" +
              "  \n" +
              "Below are the specific Roles & Responsibilities of all positions." +
              "  \n" +
              "  \n" +
              "  \n" +
              "##### **Regular Teaching Assistant Role & Responsibilities**" +
              "  \n" +
              "  \n" +
              " * Full-time, 8-hours/day, 5 days a week" +
              "  \n" +
              " * Work with 1 pod (15-20 students)" +
              "  \n" +
              " * Attend all synchronous tutorials time with your pod" +
              "  \n" +
              "    * Utilize peer-to-peer teaching methods and support the students to move through the content, including facilitating discussions and guidance through Python tutorials" +
              "  \n" +
              "    * ~4.5 hours per day including a 1 hour break" +
              "  \n" +
              "    * Ensure students are able to take scheduled breaks" +
              "  \n" +
              " * Support students during project time. Attend 45 minutes with each of the two project groups in your pod (total of 1.5 hours per day)" +
              "  \n" +
              "    * Support with project development and python coding" +
              "  \n" +
              " * Additional daily and as-needed tasks (total of 2 hours per day):" +
              "  \n" +
              "    * Review all course content ahead of time to be able to guide students and answer questions" +
              "  \n" +
              "    * Track students' attendance, review attendance waivers, and award Completion Certificates" +
              "  \n" +
              "    * Schedule meetings with your pod’s assigned Project Mentor" +
              "  \n" +
              "    * Manage your pod discord channel and reply to student questions and requests" +
              "  \n" +
              " * Complete all asynchronous TA training and attend one half-day synchronous training via Zoom" +
              "  \n" +
              " * Attend 2-hour Orientation" +
              "  \n" +
              "  \n" +
              "##### **Project Teaching Assistant Role & Responsibilities**" +
              "  \n" +
              "  \n" +
              " * Full-time, 8-hours/day, 5 days a week" +
              "  \n" +
              " * Work across two time slots during their project block" +
              "  \n" +
              " * Have experience leading graduate-level research projects" +
              "  \n" +
              " * Guide and support 7-8 project groups (usually 5-7 students per group). Including support with:" +
              "  \n" +
              "    * Brainstorming" +
              "  \n" +
              "    * Forming a research question" +
              "  \n" +
              "    * Doing a literature reviews" +
              "  \n" +
              "    * Developing python code" +
              "  \n" +
              "    * Preparing an abstract and project presentation" +
              "  \n" +
              " * Be an expert in the dataset(s) you are assigned to" +
              "  \n" +
              "    * You will select your preferred dataset in the application" +
              "  \n" +
              " * Meet with each project group daily or every other day for either an hour or 2 hours at a time respectively" +
              "  \n" +
              " * Respond to questions and requests of your pods in Discord" +
              "  \n" +
              " * Complete all asynchronous TA training and attend one half-day synchronous training via Zoom" +
              "  \n" +
              " * Attend 2-hour Orientation" +
              "  \n" +
              "  \n" +
              "##### **Lead Teaching Assistant Role & Responsibilities**" +
              "  \n" +
              "  \n" +
              " * Full-time, 8-hours/day, 5 days a week" +
              "  \n" +
              " * All responsibilities of a regular TA" +
              "  \n" +
              " * Have previous experience as a TA in an Academy course" +
              "  \n" +
              " * Support 7-8 regular TAs that make up a megapod" +
              "  \n" +
              "    * This includes support with content questions, pod dynamics, making sure TAs are prepared for the course, and answering other policy related questions" +
              "  \n" +
              " * Familiarize yourself with all content ahead of the course" +
              "  \n" +
              "    * Answer content based questions for regular TAs" +
              "  \n" +
              " * Manage TA absences by communicating with the pod and merging the pod with the absent TA into another pod from your megapod" +
              "  \n" +
              " * Schedule project presentations for your megapod on the last day" +
              "  \n" +
              " * Complete all asynchronous TA training and attend two half-day synchronous training via Zoom including one day just for Lead TAs and one day for all TAs" +
              "  \n" +
              "   * Actively participate in general TA training for your megapod" +
              "  \n" +
              " * Attend 2-hour Orientation" +
              "  \n" +
              "  \n" +
              "##### **Lead Project Teaching Assistant Role & Responsibilities**" +
              "  \n" +
              "  \n" +
              " * Full-time, 8-hours/day, 5 days a week" +
              "  \n" +
              " * All responsibilities of a regular Project TA" +
              "  \n" +
              " * Have previous experience as a Project TA in an Academy course" +
              "  \n" +
              " * Support 5-6 regular Project TAs who share your time slot" +
              "  \n" +
              "    * This includes support with content questions, pod dynamics, making sure Project TAs are prepared for the course, and answering other policy related questions" +
              "  \n" +
              " * Plan weekly Project Syncs for Project TAs in your megapod to come together for peer support and problem solving around the project groups’ projects" +
              "  \n" +
              " * Escalate any Project absences that cannot be managed by rescheduling their meeting time with a pod" +
              "  \n" +
              " * Complete all asynchronous TA training and attend two half-day synchronous training via Zoom including one day just for Lead TAs and one day for all TAs" +
              "  \n" +
              "    * Actively participate in general TA training for your megapod" +
              "  \n" +
              " * Attend 2-hour Orientation" +
              "  \n" +
              "  \n" +
              "##### **What do we offer teaching assistants?**" +
              "  \n" +
              "  \n" +
              "We will provide a stipend (amounts below) for those teaching assistants legally allowed to accept it (depending on tax or visa details). This is a lump payment for the course as well as any training prior to the course. In accordance with our organization wide [Hiring and Compensation Policy](https://docs.neuromatch.io/p/ogjO-9wZOPVo0K/Hiring-and-Compensation-Policy), TA compensation rates are adjusted based on local cost of living." +
              "  \n" +
              " * You can see your payment amound [here](https://neuromatchacademy.github.io/widgets/ta_cola.html)." +
              "  \n" +
              "  \n" +
              "We will also provide a teaching letter of support for all teaching assistants who want one, which may be particularly useful in the academic job market. To request a letter, please email [recommendations@neuromatch.io](mailto:recommendations@neuromatch.io) after the course." +
              "  \n" +
              "  \n" +
              "Teaching assistants will receive training material on the content of the course they are TAing for and on peer-to-peer and problem based teaching methods." +
              "  \n" +
              "  \n" +
              "Teaching assistants help students become better, independent researchers, arming them with the skills and knowledge needed to succeed. This is a great opportunity to make an impactful difference in not just your students’ lives, but also in worldwide scientific education going forward! " +
              "  \n" +
              "  \n" +
              "  \n" +
              "##### **Can I get paid?**" +
              "  \n" +
              "  \n" +
              "We will provide a stipend (amounts above) for those teaching assistants legally allowed to accept it (depending on tax or visa details). Teaching assistants are recruited as independent contractors, therefore it is your responsibility to ensure that you are allowed to invoice our company as an independent contractor to receive the stipend amount above.  [Here](https://airtable.com/shrKgAKus5aGrcdmn) is an example of the form from last year. If you are an international student, the international center of your university can be a valuable resource to gain further information." +
              "  \n"
            }
            </Markdown>
      </Collapse>
      <Button onClick={handleClick} className='apply-btn'>Apply Now</Button>
    </div>
  );
};
